import domtoimage from "dom-to-image"
import { saveAs } from "file-saver"
import React from "react"
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  CartesianGrid,
} from "recharts"

const CustomXLabel = (props) => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={20}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
        transform="rotate(-13)"
      >
        {payload.value}
      </text>
    </g>
  )
}

const CustomYLabel = (props) => {
  const { x, y, payload, unit } = props
  return (
    <g>
      <text
        x={x}
        y={y}
        dy={5}
        dx={-15}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
      >
        {payload.value}
        {unit}
      </text>
    </g>
  )
}

const CustomTooltip = ({ payload }) => {
  if (typeof payload === "undefined") return null
  return payload.map((entry, i) => {
    return (
      <div
        key={i}
        className="flex flex-col px-4 py-3 rounded bg-gray-50 opacity-90"
      >
        <div>
          <span className="mr-1 text-xs font-bold">Date:</span>
          <span className="text-xs">{entry.payload.date}</span>
        </div>
        <div className="mt-2">
          <span className="mr-1 text-xs font-bold">6-day weighted average</span>
          <span className="text-xs">
            {entry.payload.avg7day === null ? "-" : `${entry.payload.avg7day}`}
          </span>
        </div>
      </div>
    )
  })
}

export default function ResultsGraph({ modelData, data }) {
  const { resultsGraph } = modelData.elements
  function downloadPNG() {
    domtoimage
      .toBlob(document.getElementById(`${resultsGraph.title}-graph`))
      .then((blob) => saveAs(blob, `${modelData.title} Chart.png`))
  }

  if (data === null) return null
  let graphData = data.map((day) => {
    return {
      date: day.date,
      avg7day: day.avg7day === "-" ? null : day.avg7day,
    }
  })

  return (
    <div className="w-full" aria-hidden="true">
      <div className="flex items-center justify-between mb-6">
        <h2 className="inline-block text-lg font-semibold text-gray-600 sm:text-xl md:text-2xl">
          {resultsGraph.title}
        </h2>

        <div className="flex items-center justify-center rounded-md">
          <button
            type="button"
            aria-label="download graph in png format"
            onClick={downloadPNG}
            className="inline-flex items-center p-2 text-sm font-bold leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>
            <span className="hidden mr-1 sm:inline-block">Download </span>{" "}
            <span className="text-white">PNG</span>
          </button>
        </div>
      </div>

      <ResponsiveContainer
        width="100%"
        height={450}
        id={`${resultsGraph.title}-graph`}
      >
        <ComposedChart
          data={graphData}
          margin={{ top: 20, right: 30, left: -30, bottom: 20 }}
          className="bg-white border-b border-gray-200 shadow sm:rounded-lg"
        >
          <XAxis
            dataKey="date"
            interval={"preserveStartEnd"}
            axisLine={true}
            tick={<CustomXLabel />}
          ></XAxis>
          <YAxis
            width={110}
            id="yAxis"
            dataKey="avg7day"
            tick={<CustomYLabel unit={""} />}
            label={{
              value: "6-day weighted average",
              angle: -90,
              position: "center",
              offset: 0,
            }}
          />
          <CartesianGrid strokeDasharray="3 3" vertical={true} />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" name="6-day weighted average" height={60} />

          <Line
            type="monotone"
            dataKey="avg7day"
            stroke="#1D99E1"
            strokeWidth={2}
            dot={true}
            name={`Carbohydrate Balance`}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
