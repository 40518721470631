import axios from "axios";
import { addDays, format, getDayOfYear } from "date-fns";
import React from "react";
import { useQuery } from "react-query";
import BiofixDateInput from "../../components/models/biofixDateInput";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./apple-carbohydrate-thinning.json";
import Acknowledgments from "./_acknowledgements";
import Dropdown from "./_dropdown";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsGraph from "./_resultsGraph";
import ResultsTable from "./_resultsTable";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

async function runModel(
  sid,
  dateOfInterest,
  greenTip,
  bloomDate,
  percentFloweringSpurs
) {
  return await axios
    .get(
      `${window.location.protocol}//newa.nrcc.cornell.edu/newaTools/process_input_new?type=apple_thin&stn=${sid}&accend=${dateOfInterest}&greentip=${greenTip}&bloom=${bloomDate}&percentflowerspurs=${percentFloweringSpurs}&output=json`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(`Failed to load data`, err));
}

export default function AppleCarbohydrateThinning() {
  const h1Text = modelData.title;
  const { seasonStart, seasonEnd, elements, title, id: modelId } = modelData;
  const { dropdownItems } = elements.userInputs.firstInput.params.param5;
  const { user, isAuthenticated } = useAuth0();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();

  let filteredStationList = [];
  if (stationList) {
    let list = [...stationList];
    if (user) list = [...favoriteStations];
    filteredStationList = list.map((state) => {
      let p = { ...state };
      p.options = state.options.filter((d) => d.srqual);
      return p;
    });
  }

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  let mData = null;
  const [userBiofix1, setUserBiofix1] = React.useState(null);
  const [userBiofix2, setUserBiofix2] = React.useState(null);
  if (data && isModelInSeason && station) {
    mData = modelLogic(
      data,
      dateOfInterest,
      modelData,
      userBiofix1,
      userBiofix2
    );
  }
  // console.log(mData)
  const [percentFloweringSpurs, setPercentFloweringSpurs] = React.useState(
    dropdownItems[2]
  );

  const stationId = station === null ? "" : station.id;
  const doi = format(dateOfInterest.date, "MM-dd-yyyy");
  let greenTip;
  if (userBiofix1) {
    greenTip = format(new Date(`${userBiofix1}T00:00:00`), "MM-dd-yyyy");
  } else {
    if (mData && mData.greenTipDate) {
      greenTip = format(mData.greenTipDate.date, "MM-dd-yyyy");
    }
  }
  let bloom = "";
  if (userBiofix2) {
    bloom = format(new Date(`${userBiofix2}T00:00:00`), "MM-dd-yyyy");
  } else {
    if (mData && mData.bloomDate) {
      bloom = format(mData.bloomDate.date, "MM-dd-yyyy");
    }
  }
  const perc = percentFloweringSpurs.param;
  // console.log({ stationId, doi, greenTip, bloom, perc })
  const appleQuery = useQuery(
    `${
      station && station.name
    }(${stationId}) - doi:${doi} - greenTip:${greenTip} - bloom:${bloom} - perc:${perc}`,
    () => runModel(stationId, doi, greenTip, bloom, perc),
    {
      refetchOnWindowFocus: false,
      enabled: station !== null && isModelInSeason,
      cacheTime: 900000, // data remains in memory for 15 minutes
    }
  );

  // console.log(appleQuery)

  // console.log(userBiofix1, userBiofix2, percentFloweringSpurs)
  let isLessThan21Days = false;
  if (userBiofix1 && userBiofix2) {
    const bf1 = new Date(`${userBiofix1}T00:00:00`);
    const bf2 = new Date(`${userBiofix2}T00:00:00`);
    const bf1Doy = getDayOfYear(bf1);
    const bf2Doy = getDayOfYear(bf2);
    if (bf2Doy - bf1Doy < 21) {
      isLessThan21Days = true;
    }
  }

  // console.log({ mData })
  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const [showResultsGraph, setShowResultsGraph] = React.useState(true);
  const isVisible = station && mData;

  React.useEffect(() => {
    if (station && station.srqual === false) {
      setStation(null);
    }
  }, [station, setStation]);

  React.useEffect(() => {
    if (!userBiofix1) return;

    const bio1 = new Date(`${userBiofix1}T00:00:00`);
    const doy = getDayOfYear(bio1);
    if (dateOfInterest.dayOfYear < doy) {
      setUserBiofix1(null);
    }
    if (bio1.getFullYear() !== dateOfInterest.year) {
      setUserBiofix1(null);
      setUserBiofix2(null);
      setPercentFloweringSpurs(dropdownItems[2]);
    }
  }, [dateOfInterest, userBiofix1, dropdownItems]);

  // console.log(user)
  React.useEffect(() => {
    if (!user) return;

    if (Object.keys(user.activeTools)) {
      const model = user.activeTools.find((model) => model.id === modelId);
      if (model) {
        // green Tip Date ---------------------------------------
        if (
          Object.keys(model.greenTip).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.greenTip[dateOfInterest.year]).includes(station.id)
        ) {
          setUserBiofix1(model.greenTip[dateOfInterest.year][station.id]);
        } else {
          setUserBiofix1(null);
        }
        // Bloom Date ----------------------------------------------
        if (
          Object.keys(model.bloom).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.bloom[dateOfInterest.year]).includes(station.id)
        ) {
          setUserBiofix2(model.bloom[dateOfInterest.year][station.id]);
        } else {
          setUserBiofix2(null);
        }
        // Percent Flowering Spurs ------------------------------------
        if (
          Object.keys(model.percentFloweringSpurs).includes(
            `${dateOfInterest.year}`
          ) &&
          Object.keys(
            model.percentFloweringSpurs[dateOfInterest.year]
          ).includes(station.id)
        ) {
          setPercentFloweringSpurs(
            model.percentFloweringSpurs[dateOfInterest.year][station.id]
          );
        } else {
          setPercentFloweringSpurs(dropdownItems[2]);
        }
      }
    }
  }, [station, modelId, user, dateOfInterest, dropdownItems]);

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      station={station}
      stationList={filteredStationList}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading || appleQuery.isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-base lg:text-xl"}
      moreInfo={<MoreInfo moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      showResultsChart={showResultsGraph}
      setShowResultsChart={setShowResultsGraph}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      {/* User Input */}
      <div className="mb-16 sm:mb-18 md:mb-20">
        {isModelInSeason && isVisible && (
          <>
            <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
              Green Tip & Bloom Dates
            </h2>
            <div className="px-6 py-6 text-center bg-white rounded-lg shadow-lg sm:text-left">
              {userBiofix1 === null &&
                userBiofix2 === null &&
                mData &&
                mData.greenTipDate && (
                  <div className="flex items-center justify-center px-2 rounded-md bg-gray-50">
                    <p className="text-sm text-justify sm:text-left">
                      Green tip and bloom dates below are estimated from growing
                      degree day accumulations. Enter your orchard's dates to
                      fine-tune results.
                    </p>
                  </div>
                )}
              <div className="grid max-w-2xl grid-cols-1 gap-4 py-4 mx-auto lg:grid-cols-3">
                <div className="w-56 place-self-center">
                  <BiofixDateInput
                    modelId={modelId}
                    id={"greenTip"}
                    dbKey="greenTip"
                    minDate={new Date(`${dateOfInterest.year}-${seasonStart}`)}
                    maxDate={dateOfInterest.date}
                    biofix={mData.greenTipDate}
                    userBiofix={userBiofix1}
                    setUserBiofix={setUserBiofix1}
                    setUserBiofix2={setUserBiofix2}
                    label={elements.userInputs.firstInput.params.param3.label}
                    modelName={title}
                    tooltip={`Reset green tip to default value`}
                    datesWithNoData={[]}
                    isDisabled={false}
                    station={station}
                  ></BiofixDateInput>
                </div>

                <div className="w-56 place-self-center">
                  <BiofixDateInput
                    modelId={modelId}
                    id={"bloom"}
                    dbKey="bloom"
                    minDate={
                      mData && mData.greenTipDate
                        ? addDays(mData.greenTipDate.date, 1)
                        : null
                    }
                    maxDate={dateOfInterest.date}
                    biofix={
                      mData &&
                      mData.greenTipDate &&
                      mData.bloomDdate &&
                      mData.greenTipDate.ms > mData.bloomDate.ms
                        ? null
                        : mData.bloomDate
                    }
                    userBiofix={userBiofix2}
                    setUserBiofix={setUserBiofix2}
                    label={elements.userInputs.firstInput.params.param4.label}
                    modelName={title}
                    tooltip={`Reset bloom date to default value`}
                    datesWithNoData={[]}
                    isDisabled={mData.greenTipDate === null}
                    station={station}
                  ></BiofixDateInput>
                </div>

                <div className="w-56 place-self-center">
                  <div className="mb-2 font-semibold leading-5 text-left text-gray-700">
                    Percent Flowering Spurs
                  </div>
                  <Dropdown
                    modelId={modelId}
                    id="percentFloweringSpurs"
                    dbKey="percentFloweringSpurs"
                    modelName={title}
                    width="w-full"
                    ariaLabel="Percent Flowering Spurs"
                    list={dropdownItems}
                    item={percentFloweringSpurs}
                    setUserItem={setPercentFloweringSpurs}
                    station={station}
                  ></Dropdown>
                </div>
              </div>
              {isLessThan21Days && (
                <p className="text-sm font-semibold tracking-wide text-center text-red-800">
                  Difference between Green tip and Bloom is less than 21 days.
                  Results may be unreliable.
                </p>
              )}

              <div className="flex items-center justify-center px-2 mt-10 rounded-md bg-gray-50">
                <p className="text-sm leading-6 text-justify sm:text-left">
                  <b>Note from the model developer (May 7, 2021): </b>
                  The apple carbohydrate model simulates the response to weather
                  of trees that are healthy with normal vigor and bloom, no
                  significant water, nutrient or winter or spring freeze stress,
                  and no significant carry-over stress from a previous year that
                  will change tree responses. We are less confident in the model
                  if temperatures are extremely cold or hot. Each orchard is
                  unique, so use this tool, as any other, in the context of your
                  own experience. % flowering spurs which growers should input
                  refers to the number of flowering spurs per 100 spurs not
                  counting bloom on 1-year wood. This can be counted or
                  estimated visually. The weighted 6-day average carbohydrate
                  balance is calculated by weighting the balance of each day as
                  follows; 1 day before application=50% weight, day of
                  application plus 1, 2 and 3 days after application=100%
                  weight, day 4 after application=weight 50%. For more
                  information click on the "More Info" tab.
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Results Table */}
      {isModelInSeason && showResultsTable && appleQuery.data && isVisible && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable
            station={station}
            modelData={modelData}
            tableData={appleQuery.data.data}
            csvData={appleQuery.data.data}
            isLoading={appleQuery.isLoading}
            bloomDate={mData && mData.bloomDate ? mData.bloomDate : null}
          ></ResultsTable>
        </div>
      )}

      {/* Results Graph */}
      {isModelInSeason &&
        showResultsGraph &&
        appleQuery.data &&
        isVisible &&
        !appleQuery.isFetching && (
          <div className="mb-16 sm:mb-18 md:mb-20">
            <ResultsGraph
              modelData={modelData}
              data={appleQuery.data.data}
            ></ResultsGraph>
          </div>
        )}
    </ModelLayout>
  );
}
