import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Lakso, A.N., Robinson, T.L., and Green, D.W. 2007. Using an apple tree
        carbohydrate model to understand thinning responses to weather and
        chemical thinners. NY Fruit Quarterly. 15 (3) pp 17-20.
      </p>
    </>
  )
}
