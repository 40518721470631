import { calculateGdd, baskervilleEmin } from "../../utils/helpers"
import { format } from "date-fns"

export default function appleCarbohydrateThinningLogic(
  data,
  dateOfInterest,
  modelData,
  userBiofix1,
  userBiofix2
) {
  const { base, elements } = modelData
  const { userInputs } = elements

  const dailyDataWithGdd = calculateGdd(
    data.dailyData,
    base,
    0,
    baskervilleEmin
  ).map((d) => {
    return { ...d, dateDisplay: format(d.date, "yyyy-MM-dd") }
  })
  // console.log(dailyDataWithGdd)
  ////////////////////////////////////////////////////////////////////////////////////

  // User Biofix1 dates -------------------------------------------------------
  let userBiofix1Index = 0
  if (userBiofix1 !== null) {
    userBiofix1Index = dailyDataWithGdd.findIndex(
      (day) => day.dateDisplay === userBiofix1
    )
  }

  // User Biofix2 dates -------------------------------------------------------
  let userBiofix2Index = 0
  if (userBiofix2 !== null) {
    userBiofix2Index = dailyDataWithGdd.findIndex(
      (day) => day.dateDisplay === userBiofix2
    )
  }
  ////////////////////////////////////////////////////////////////////////////////////

  // Recalculating degree-days when biofix1 ----------------------------------
  let gdd1 = 0
  let gdd2 = 0
  let indices1 = []
  let indices2 = []
  const mData = dailyDataWithGdd.map((day, i) => {
    let p = {
      date: day.date,
      dateDisplay: day.dateDisplay,
      ms: day.ms,
      dayOfYear: day.dayOfYear,
      isForecast: day.isForecast,
      dd: day.dd,
      gdd: day.gdd,
      greenTipGdd: null,
      bloomDateGdd: null,
    }

    // Recalculate if user has selected a biofix1 date
    if (userBiofix1 !== null) {
      if (i >= userBiofix1Index) {
        indices1.push(i)
        gdd1 += day.dd !== "N/A" ? day.dd : 0
        p.greenTipGdd = Math.round(gdd1)
      }
    } else {
      if (day.gdd >= userInputs.firstInput.params.param3.biofix) {
        indices1.push(i)
        gdd1 += day.dd !== "N/A" ? day.dd : 0
        p.greenTipGdd = Math.round(gdd1)
      }
    }

    // Recalculate if user has selected a biofix2 date
    if (userBiofix2 !== null) {
      if (i >= userBiofix2Index) {
        indices2.push(i)
        gdd2 += day.dd !== "N/A" ? day.dd : 0
        p.bloomDateGdd = Math.round(gdd2)
      }
    } else {
      if (day.gdd >= userInputs.firstInput.params.param4.biofix) {
        indices2.push(i)
        gdd2 += day.dd !== "N/A" ? day.dd : 0
        p.bloomDateGdd = Math.round(gdd2)
      }
    }
    return p
  })
  // console.log(mData)
  ////////////////////////////////////////////////////////////////////////////////////

  //  Green Tip Date ----------------------------------------------------------
  let greenTipDate = null
  if (indices1.length !== 0) {
    const dd = mData[indices1[0]]
    if (dd.dayOfYear <= dateOfInterest.dayOfYear) {
      greenTipDate = dd
    }
  }

  //  Bloom Tip Date ----------------------------------------------------------
  let bloomDate = null
  if (indices2.length !== 0) {
    const dd = mData[indices2[0]]
    if (dd.dayOfYear <= dateOfInterest.dayOfYear) {
      bloomDate = dd
    }
  }
  ////////////////////////////////////////////////////////////////////////////////////

  let selectedDate = mData.find(
    (day) => day.dayOfYear === dateOfInterest.dayOfYear
  )
  if (!selectedDate) {
    selectedDate = mData.find((d) => d.isForecast)
  }

  ////////////////////////////////////////////////////////////////////////////////////
  return {
    selectedDate,
    greenTipDate,
    bloomDate,
  }
}
