import GlobalStateContext from "../../context/globalStateContext"
import React from "react"
import { CSVLink } from "react-csv"
import HashLoader from "react-spinners/HashLoader"
import { getDayOfYear, format } from "date-fns"

const th = `px-1 py-2 text-xs lg:text-sm leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800 sticky top-0 shadow-sm`
const td = `text-xs lg:text-sm px-3 py-1 border border-gray-200 leading-6 text-gray-700 whitespace-nowrap bg-white`
const cell =
  "flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm font-medium bg-white text-gray-800"

const thinningBg = (riskColor) => {
  let char
  let color
  if (riskColor === 1) {
    char = "D"
    color = "bg-red-500"
  }
  if (riskColor === 2) {
    char = "C"
    color = "bg-yellow-400"
  }
  if (riskColor === 3) {
    char = "L"
    color = "bg-green-500"
  }

  return (
    <span
      className={`flex items-center justify-center w-6 h-6 p-1 text-xs ${color} rounded-full text-white font-bold`}
    >
      {char}
    </span>
  )
}

const rowBg = (efficacy) => {
  if (efficacy === "NA") return "bg-gray-100 text-gray-800"
  if (efficacy === "Mild") return "bg-blue-100 text-blue-800"
  if (efficacy === "Good") return "bg-green-100 text-green-800"
  if (efficacy === "Very Good") return "bg-orange-100 text-orange-800"
  if (efficacy === "Excessive") return "bg-red-100 text-red-800"
}

export default function ResultsTable({
  station,
  modelData,
  tableData,
  csvData,
  isLoading,
  bloomDate,
}) {
  const { resultsTable } = modelData.elements
  const { dateOfInterest } = React.useContext(GlobalStateContext)

  if (!tableData) return null

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center w-full">
          <HashLoader size={61} color={"#1987C2"} loading={true} />
        </div>
      ) : (
        <div className="w-full">
          <div className="flex items-center justify-between mb-3">
            <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
              {resultsTable.title}
            </h2>

            <div className="flex justify-center rounded-md shadow-sm">
              <button
                type="button"
                aria-label="download results table data in csv format"
                className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md lg:px-3 lg:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
              >
                <svg
                  className="-ml-0.5 mr-2 h-4 w-4"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
                </svg>

                <CSVLink
                  className="text-white no-underline"
                  filename={`${modelData.title} Results Table.csv`}
                  data={csvData}
                >
                  <span className="hidden text-white lg:inline-block">
                    Download
                  </span>{" "}
                  <span className="text-white">CSV</span>
                </CSVLink>
              </button>
            </div>
          </div>

          <div className="flex flex-col my-4 lg:flex-row lg:justify-between lg:items-center">
            <div className="flex items-center">
              <span className="inline-block py-2 mr-4">
                <a
                  className="text-xs lg:text-sm"
                  href={`http://forecast.weather.gov/MapClick.php?textField1=${station.lat}&textField2=${station.lon}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Forecast Details
                </a>
              </span>
            </div>

            <div className="flex flex-col mt-2 text-sm mr-60 lg:mt-0 lg:items-center lg:flex-row">
              <span className="mb-2 font-bold tracking-wider text-gray-700 lg:mb-0 lg:mr-3">
                Thinning efficacy:
              </span>
              <div className="grid grid-cols-4 gap-1 lg:gap-2">
                <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm bg-blue-100 text-blue-800 font-semibold">
                  Mild
                </span>
                <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm bg-green-100 text-green-800 font-semibold font">
                  Good
                </span>
                <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm bg-orange-100 text-orange-800 font-semibold">
                  Very Good
                </span>
                <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm bg-red-100 text-red-800 font-semibold">
                  Excessive
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="py-2 -my-2 overflow-x-auto lg:-mx-6 lg:px-6 xl:-mx-8 xl:px-8">
              <div className="inline-block min-w-full overflow-y-auto align-middle border-b border-gray-200 shadow h-128 lg:rounded-lg">
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className={th} rowSpan="2">
                        Date <small>({dateOfInterest.year})</small>
                      </th>
                      <th className={th} rowSpan="2">
                        Max Temp (˚F)
                      </th>
                      <th className={th} rowSpan="2">
                        Min Temp (˚F)
                      </th>
                      <th className={th} rowSpan="2">
                        Solar Rad (MJ/m2)
                      </th>
                      <th className={th} colSpan="2" rowSpan="2">
                        <div className="grid grid-cols-1">
                          <div className="pb-6 border-b">
                            Tree Carbohydrate Status (g/day)
                          </div>
                          <div className="grid grid-cols-2 pt-2 divide-x">
                            <div className="flex items-center justify-center w-full">
                              Daily
                            </div>
                            <div className="flex items-center justify-center w-full">
                              <div className="flex flex-col">
                                <span>6-Day</span>
                                <span>weighted average</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th className={th} rowSpan="2">
                        <div className="flex flex-col items-center justify-center px-2 mx-auto w-36">
                          <span className="block">Accum 4°C DD</span>
                          <span className="block">since bloom</span>
                          {/* <div className="mt-8 text-xs text-gray-300 whitespace-nowrap">
                            Cell Border &#8805; 200
                          </div>
                          <div className="mt-0 text-xs text-gray-300 whitespace-nowrap">
                            Cell Border &#8806; 250
                          </div> */}
                          <div className="flex items-center justify-center w-full h-6 mt-8 text-xs  border border-gray-700 px-2.5 py-0.5 rounded-md font-medium text-gray-300">
                            &#8805; 200 & &#8806; 250
                          </div>
                        </div>
                      </th>
                      <th className={th} rowSpan="2">
                        Thinning Recommendation
                        <div className="flex flex-col items-start mx-auto mt-4 space-y-1 w-52">
                          <div className="flex items-center justify-between">
                            <span className="flex items-center justify-center w-6 h-6 p-1 text-xs font-bold text-white bg-green-500 rounded-full">
                              L
                            </span>
                            <span className="px-2">=</span>
                            <span className="text-xs">
                              Low Risk of Overthinning
                            </span>
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="flex items-center justify-center w-6 h-6 p-1 text-xs font-bold text-white bg-yellow-400 rounded-full">
                              C
                            </span>
                            <span className="px-2">=</span>
                            <span className="text-xs">Caution</span>
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="flex items-center justify-center w-6 h-6 p-1 text-xs font-bold text-white bg-red-500 rounded-full">
                              D
                            </span>
                            <span className="px-2">=</span>
                            <span className="text-xs">
                              Danger of Overthinning
                            </span>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((d) => {
                      const doy = getDayOfYear(new Date(`${d.date}T00:00`))
                      let shouldBeColored = false
                      if (bloomDate) {
                        if (
                          doy >= bloomDate.dayOfYear &&
                          doy <= bloomDate.dayOfYear + 35
                        ) {
                          shouldBeColored = true
                        }
                      }
                      return (
                        <tr key={d.date} className={`text-center`}>
                          <td className={`${td}`}>
                            <span className={`${cell}`}>
                              <span className="flex flex-col items-center">
                                {format(new Date(`${d.date}T00:00`), "MMM d")}
                                <small className="font-bold text-gray-500">
                                  {dateOfInterest.isCurrentYear &&
                                  doy > getDayOfYear(new Date())
                                    ? "Forecast"
                                    : ""}
                                </small>
                              </span>
                            </span>
                          </td>
                          <td className={`${td}`}>
                            <span className={`${cell}`}>{d.maxt}</span>
                          </td>
                          <td className={`${td}`}>
                            <span className={`${cell}`}>{d.mint}</span>
                          </td>
                          <td className={`${td}`}>
                            <span className={`${cell}`}>{d.srad}</span>
                          </td>
                          <td className={`${td}`}>
                            <span
                              className={`${cell} ${
                                shouldBeColored && rowBg(d.efficacy)
                              }`}
                            >
                              {d.thinIndex}
                            </span>
                          </td>
                          <td className={`${td}`}>
                            <span
                              className={`${cell} ${
                                shouldBeColored && rowBg(d.efficacy)
                              }`}
                            >
                              {d.avg7day}
                            </span>
                          </td>
                          <td className={`${td}`}>
                            <span
                              className={`${cell} ${
                                shouldBeColored && rowBg(d.efficacy)
                              } ${
                                d.dd4cAccum >= 200 && d.dd4cAccum <= 250
                                  ? `border border-gray-700`
                                  : ``
                              }`}
                            >
                              {d.dd4cAccum}
                            </span>
                          </td>
                          <td className={`${td} w-52`}>
                            {d.riskColor === 0 ? (
                              <span>{d.recommend}</span>
                            ) : (
                              <div className="flex items-center justify-between">
                                <span className="whitespace-pre-line">
                                  {d.recommend}
                                </span>
                                <span className="ml-2">
                                  {thinningBg(d.riskColor)}
                                </span>
                              </div>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
