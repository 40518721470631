import React from "react"

export default function MoreInfo() {
  return (
    <ul className="px-4 mt-6 text-sm text-left list-disc">
      <li className="mb-4">
        <a
          href=" https://live-cu-newa.pantheonsite.io/wp-content/uploads/robinson-et-al-2020-improvements-to-the-apple-carbohydrate-thinning-model-nyfq.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Improvements to the Cornell Apple Carbohydrate Thinning Model –
          MaluSim
        </a>
      </li>

      <li className="mb-4">
        <a
          href=" https://live-cu-newa.pantheonsite.io/wp-content/uploads/more-info-apple-carbohydrate-thinning-model-20210215.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Apple Carbohydrate Thinning Model
        </a>
      </li>
    </ul>
  )
}
