import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"
import VisuallyHidden from "@reach/visually-hidden"
import React from "react"

export default function Modal({ title, isModalOpen, setModal, children }) {
  const close = () => setModal(false)
  const labelId = `label--${Date.now()}`
  return (
    <div>
      {isModalOpen && (
        <Dialog
          isOpen={isModalOpen}
          onDismiss={close}
          aria-labelledby={labelId}
          className="max-w-lg rounded-lg"
        >
          <button className="text-3xl close-button" onClick={close}>
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>×</span>
          </button>
          <div className="mt-4">
            <h1 id={labelId}>{title}</h1>
            <div>{children}</div>
          </div>
        </Dialog>
      )}
    </div>
  )
}
