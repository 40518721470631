import {getDayOfYear} from 'date-fns'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import GlobalStateContext from '../../context/globalStateContext'
import {setDate} from '../../utils/helpers'

export default function DateOfInterest({
  setSidebarOpen,
  isMobile = false,
  datesWithNoData,
  yearDatePicker = false,
  maxDate,
}) {
  const {dispatch, dateOfInterest} = React.useContext(GlobalStateContext)
  const [dd, setDD] = React.useState(dateOfInterest.date)

  const renderDayContents = (day, date) => {
    let title = ''
    if (datesWithNoData) {
      const dayOfYear = getDayOfYear(date)
      const isDayMissing = datesWithNoData.includes(dayOfYear)
      if (isDayMissing) title = 'No Data Available'
    }
    return <span title={title}>{day}</span>
  }

  function handleOnChange(date) {
    let d = date
    if (yearDatePicker) {
      const month = new Date().getMonth()
      const day = new Date().getDate()
      const hour = new Date().getHours()
      d = new Date(date.getFullYear(), month, day, hour, 0, 0, 0)
    }

    setDD(d)
    dispatch({type: 'setDateOfInterest', dateOfInterest: setDate(d)})
    if (isMobile) {
      setSidebarOpen(false)
    }
  }

  React.useEffect(() => {
    if (maxDate === null) return
    setDD(maxDate)
    dispatch({type: 'setDateOfInterest', dateOfInterest: setDate(maxDate)})
  }, [setDD, dispatch, maxDate])

  return (
    <DatePicker
      className="px-2 py-1.5 border border-gray-300 rounded-xl"
      id="dateOfInterest"
      selected={dd}
      onChange={handleOnChange}
      minDate={new Date(2019, 1, 1, 0)}
      maxDate={maxDate ? maxDate : new Date()}
      inline={yearDatePicker ? false : true}
      renderDayContents={renderDayContents}
      showYearDropdown
      fixedHeight
      showYearPicker={yearDatePicker}
      dateFormat={yearDatePicker ? 'yyyy' : ''}
    />
  )
}
